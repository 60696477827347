<template>
    <FormWrapper
      :form="form"
      :hideForm="hideForm"
      :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    >
      <div class="form-group mb-0 break-spaces">
        <ScreeningResume 
          :loading="loading"
          :screening="this.form.fields"
        />

      </div>
    </FormWrapper>
  </template>
  
  <script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'
  
  export default {
    components: {
      FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
      ScreeningResume: () => import('@/components/Attendance/Forms/Components/ScreeningResume'),
    },
    computed: {
      ...mapState({
        canEdit: state => state.attendance.canEdit,
        form: state => state.attendance.form.bvTriagem,
        medicalRecordsStore: state => state.attendance.medicalRecordsStore,
        attendance: state => state.attendance.attendance,
      }),
      ...mapGetters('attendance', ['bvConsultation', 'cidConsultation']),
    },
    mixins: [attendanceDataMixin('medicalRecordsStore')],
    data() {
      return {
        loading: false,
        data: null
      }
    },
    methods: {
      ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
      ...mapActions('attendance/form/bvTriagem', ['handleFields', 'handleProps']),
      updateForm(key, value) {
        this.handleFields({ key, value })
      },
      hideForm(){
        this.handleProps({ key: 'showing', value: false })
        this.destroyMedicalRecord(this.form)
        Object.keys(this.form.fields).forEach(key => {
          this.form.fields[key] = null
        });
      },
      async getBvTriagem() {
        const bvTriagem = this.bvConsultation?.screening?.response

        this.form.fields.lastVisitInMonths = bvTriagem?.lastVisitInMonths
        this.form.fields.pathologiesUnderTreatment = bvTriagem?.pathologiesUnderTreatment
        this.form.fields.eyeDiscomfort = bvTriagem?.eyeDiscomfort
        this.form.fields.eyeConditions = bvTriagem?.eyeConditions
        this.form.fields.glassesOrLenses = bvTriagem?.glassesOrLenses
        this.form.fields.hasLastPrescription = bvTriagem?.hasLastPrescription
        this.form.fields.usesContinuousMeds = bvTriagem?.usesContinuousMeds
        this.form.fields.informDegree = bvTriagem?.informDegree
        this.form.fields.informDegreeEPI = bvTriagem?.informDegreeEPI

        this.form.fields.esferico_E = bvTriagem?.informDegree?.esferico_E
        this.form.fields.cilindrico_E = bvTriagem?.informDegree?.cilindrico_E
        this.form.fields.eixo_E = bvTriagem?.informDegree?.eixo_E
        this.form.fields.esferico_D = bvTriagem?.informDegree?.esferico_D
        this.form.fields.cilindrico_D = bvTriagem?.informDegree?.cilindrico_D
        this.form.fields.eixo_D = bvTriagem?.informDegree?.eixo_D
        this.form.fields.epiHasDegreeCorrection = bvTriagem?.epiHasDegreeCorrection
        this.form.fields.hasEPI = bvTriagem?.hasEPI

        // this.form.fields.EPIesferico_E = bvTriagem?.informDegreeEPI?.esferico_E
        // this.form.fields.EPIcilindrico_E = bvTriagem?.informDegreeEPI?.cilindrico_E
        // this.form.fields.EPIeixo_E = bvTriagem?.informDegreeEPI?.eixo_E
        // this.form.fields.EPIesferico_D = bvTriagem?.informDegreeEPI?.esferico_D
        // this.form.fields.EPIcilindrico_D = bvTriagem?.informDegreeEPI?.cilindrico_D
        // this.form.fields.EPIeixo_D = bvTriagem?.informDegreeEPI?.eixo_D

        this.form.fields.hasEPISameDegreeAsGlasses = bvTriagem?.hasEPISameDegreeAsGlasses
        this.form.fields.medicinesList = bvTriagem?.medicinesList
        this.form.fields.seeWellWithoutGlasses = bvTriagem?.seeWellWithoutGlasses
        this.form.fields.alreadyHadPrescription = bvTriagem?.alreadyHadPrescription
        this.form.fields.canInformPrescriptionDegree = bvTriagem?.canInformPrescriptionDegree
        this.form.fields.createdAt = this.bvConsultation?.screening?.createdAt

        this.updateMedicalRecord(this.form)
      },
    },
    watch: {
      'bvConsultation': function (value) {
        if(value){
          this.getBvTriagem()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>

.subtitle {
    color: var(--type-active, #525C7A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0.6rem;
}

.inline {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: .5rem;
    text-align: left;

    b {
        font-weight: 700;
        padding-left: 5px;
    }
}
</style>